<template>
  <div v-loading="pageLoading" class="bg">
    <div class="top_div" @click="goBack">
      <i class="el-icon-arrow-left left_link"></i>测评报告
    </div>
    <div class="user_info">
      <div class="time">{{ detail.createTime }}</div>
      <div class="detail">
        <div class="ava">
          <img class="" src="@/assets/ava_man.png" />
        </div>
        <div class="content">
          <div class="line_1">
            <div>姓名：{{ patient_detail.name }}</div>
            <div>性别：{{ patient_detail.sex }}</div>
          </div>
          <div>年龄：{{ patient_detail.age }}岁</div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="main_div">
      <div class="first">
        <div class="res_tags">综合结果</div>
        <div class="img_div">
          <img
            v-if="result_detail.level_pic == '极高'"
            class="result_img"
            src="@/assets/level_hight.png"
          />
          <img
            v-else-if="result_detail.level_pic == '高'"
            class="result_img"
            src="@/assets/level_center.png"
          />
          <img
            v-else-if="result_detail.level_pic == '中'"
            class="result_img"
            src="@/assets/level_low.png"
          />
          <img v-else class="result_img" src="@/assets/level_normal.png" />
          <div class="picture_text">{{ result_detail.level }}</div>
        </div>
        <div class="first_result">
          您的心理状态处于：
          <span
            v-if="
              result_detail.level_pic == '高' ||
              result_detail.level_pic == '极高'
            "
            class="first_result_color_red"
          >
            {{ result_detail.level }}
          </span>
          <span v-else class="first_result_color">
            {{ result_detail.level }}
          </span>
        </div>
        <div class="tips">*注明：最终结果以医护人员确认为准。</div>
      </div>
      <div class="second">
        <div class="res_tags">量表测评结果</div>
        <div class="card">
          <div class="second_msg">
            {{ result_detail.result }}
          </div>
          <div v-if="result_detail.error_num == 0" class="second_unerror">
            无异常
          </div>
          <div v-else class="second_error">
            {{ result_detail.error_num }}项异常
          </div>
        </div>
      </div>
      <div class="third">
        <div class="res_tags">提示</div>
        <div class="card">{{ result_detail.tips }}</div>
      </div>
      <div class="fourth">
        <div class="res_tags">建议</div>
        <div class="card">{{ result_detail.suggest }}</div>
      </div>
      <div class="bottom_btn" @click="goDetail">获取详细报告</div>
    </div>
  </div>
</template>

<script>
import { getDetail } from "@/api/wenjuanResult";
export default {
  name: "ReportSummary",
  data() {
    return {
      id: null,
      detail: {
        createTime: "",
      },
      patient_detail: {
        name: "",
        sex: "",
        age: "",
      },
      result_detail: {
        level: "",
        level_pic: "",
        error_num: "",
        result: "",
        tips: "",
        suggest: "",
      },
      pageLoading: true,
    };
  },
  mounted() {
    //没有问卷id就去选择对应的问卷
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    } else {
      this.go_index();
    }
    this.fetchDetail();
  },
  methods: {
    //跳转至选择病人页面
    go_choose_patient() {
      this.$router.push({
        path: "choose_patient",
      });
    },
    //点击顶部的返回按钮
    goBack() {
      let that = this;
      this.$confirm("确定要返回选择患者页面吗?", "提示", {
        confirmButtonText: "确定返回",
        cancelButtonText: "再想一想",
        type: "warning",
        buttonSize: "large",
      })
        .then(() => {
          that.go_index();
        })
        .catch(() => {});
    },
    //跳转至选择问卷页面
    go_index() {
      this.$router.push({
        path: "/",
        query: { uid: this.uid },
      });
    },
    goDetail() {
      this.$router.push({
        path: "/report_detail",
        query: { id: this.id },
      });
    },
    //获取数据详情
    async fetchDetail() {
      this.pageLoading = true;
      try {
        const {
          data: { detail, patient_detail, result_detail },
        } = await getDetail(this.id);
        this.detail = detail;
        this.patient_detail = patient_detail;
        this.result_detail = result_detail;
      } finally {
        this.pageLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  padding-bottom: 49px;
  background-color: #379884;
  background-image: url(../../assets/report_bg.png);
  background-repeat: no-repeat;
  background-position: initial;
  background-size: inherit;
}

.top_div {
  display: flex;
  align-items: center;
  width: 100%;
  height: 131px;
  font-size: 48px;
  font-weight: bold;
  color: #fff;
}
.link_div {
  width: 50px;
}
.left_link {
  margin-left: 15px;
}
.top_title {
  flex: 1;
  text-align: center;
}

.user_info {
  width: 855px;
  padding: 45px;
  padding-bottom: 80px;
  margin: 0px auto;
  margin-top: 72px;
  background: linear-gradient(180deg, #daf7f1, #ffffff);
  border-radius: 20px 20px 0px 0px;

  .time {
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 400;
    line-height: 40px;
    color: #333333;
    text-align: right;
  }
  .detail {
    display: flex;
    align-items: center;
    font-size: 36px;
    font-weight: 400;
    line-height: 40px;
    color: #333333;
    .content {
      flex: 1;
      padding-left: 25px;
    }
    .line_1 {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 40px;
      div {
        flex: 1;
      }
    }
  }
}

.line {
  width: 945px;
  height: 21px;
  margin: 0px auto;
  background: #f2f2f2;
}
.main_div {
  width: 893px;
  padding: 39px 26px;
  margin: 0px auto;
  background: #ffffff;
  border-radius: 0px 0px 20px 20px;

  .res_tags {
    height: 62px;
    font-size: 36px;
    font-weight: 400;
    line-height: 62px;
    color: #ffffff;
    text-align: center;
    background: #00ba88;
    border-radius: 20px 20px 0px 0px;
  }
  .card {
    display: flex;
    justify-content: space-between;
    padding: 75px 25px;
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
    color: #606266;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 15px 30px 2px rgba(167, 189, 183, 0.21);
  }

  .first {
    .res_tags {
      width: 196px;
    }
    .img_div {
      margin-top: 76px;
      text-align: center;
    }
    .result_img {
      margin: 0px auto;
    }
    .picture_text {
      position: relative;
      top: -190px;
      width: 120px;
      margin: 0px auto;
      font-size: 36px;
      color: #fff;
      text-align: center;
    }
    .first_result {
      // margin-top: 57px;
      font-size: 39px;
      font-weight: 400;
      line-height: 44px;
      color: #606266;
      text-align: center;
    }
    .first_result_color {
      font-size: 52px;
      font-weight: 400;
      color: #c0e13a;
    }
    .first_result_color_red {
      font-size: 52px;
      font-weight: 400;
      color: #fdc600;
    }
    .tips {
      margin-top: 31px;
      font-size: 30px;
      font-weight: 400;
      line-height: 42px;
      color: #999999;
      text-align: center;
    }
  }
  .second {
    margin-top: 151px;
    .res_tags {
      width: 271px;
    }
    .second_msg {
      font-size: 36px;
      font-weight: 400;
      line-height: 44px;
      color: #606266;
    }
    .second_error {
      width: 144px;
      height: 54px;
      font-size: 30px;
      font-weight: 400;
      line-height: 54px;
      color: #ffffff;
      text-align: center;
      background: #f56c6c;
      border-radius: 27px;
    }
    .second_unerror {
      width: 144px;
      height: 54px;
      font-size: 30px;
      font-weight: 400;
      line-height: 54px;
      color: #ffffff;
      text-align: center;
      background: #c0e13a;
      border-radius: 27px;
    }
  }
  .third {
    margin-top: 50px;
    .res_tags {
      width: 136px;
    }
  }
  .fourth {
    margin-top: 50px;
    .res_tags {
      width: 136px;
    }
  }
}
.bottom_btn {
  width: 518px;
  height: 118px;
  margin: 135px auto;

  font-size: 48px;
  font-weight: 400;
  line-height: 118px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background: #00ba88;
  border-radius: 20px;
}
</style>
